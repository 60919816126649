@font-face {
  font-family: 'Graphik';
  src: url('/assets/2022-theme/fonts/Graphik-Bold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/2022-theme/fonts/Graphik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/2022-theme/fonts/Graphik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/2022-theme/fonts/Graphik-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/2022-theme/fonts/Graphik-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('/assets/2022-theme/fonts/PPTelegraf-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('/assets/2022-theme/fonts/PPTelegraf-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Heavy.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-HeavyItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-SemiBoldItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-BlackItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Regular.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-ExtraBold.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Italic.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Medium.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-ExtraBoldItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Bold.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Black.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-BoldItalic.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Thin.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-ThinItalic.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-LightItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-MediumItalic.woff2')
      format('woff2'),
    url('/fonts/Axiforma/Axiforma-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-Light.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/Axiforma/Axiforma-SemiBold.woff2') format('woff2'),
    url('/fonts/Axiforma/Axiforma-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}